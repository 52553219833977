
/* Estilos para pantallas pequeñas */
@media (max-width: 768px) {
    .map-wrap {
        flex-direction: column; /* Disposición vertical en pantallas pequeñas */
    }

    .map {
        width: 100%; /* Ancho completo */
        height: 50vh; /* La mitad de la altura de la pantalla */
    }
    .mapp {
        height: 200px;
    
    }

    .profileContainer {
        width: 100%; /* Ancho completo */
        height: 200px; /* Altura fija para el contenedor del perfil de elevación */
    }
}

.parent-container {
    /*padding: 100px 0;*/
    padding-top: 80px;
    padding-bottom: 30px;
}

.map-wrapp {
    position: relative;
    width: 100%;
    height: calc(100vh - 77px);
    display: flex;
    flex-direction: row; /* Disposición horizontal por defecto */
}

.mapp {
    height: 300px;

}

.profileContainerr {
    background: #252525;
    width: 50vw; /* La mitad del ancho de la pantalla */
    height: 100%;
    margin-top: 0;
    position: relative; /* Cambiar a relative para facilitar el posicionamiento */
    bottom: 0;
}


.data-block {
    margin-bottom: 11px;
    width: 100px;
    display: inline-block;
    vertical-align: top; /* Para alinear los divs en la parte superior */
    text-align: left;
}
.data-bit{
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
}
.data-label{
    color: #6c6c6c;
    font-size: 12px;
    vertical-align: middle;
}